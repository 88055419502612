<script setup lang="ts">
const appName = import.meta.env.VUE_APP_NAME;
</script>

<template>
  <div>
    <pre>{{ appName }}</pre>
    <pre>feature 1 fixed</pre>
    <ul>
      <li><router-link to="/">home</router-link></li>
      <li><router-link to="/profile">profile</router-link></li>
    </ul>
    <router-view />
  </div>
</template>
