import { createApp } from 'vue';
import { createRouter, createWebHistory } from 'vue-router';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';
import App from './app.vue';

const app = createApp(App);
const router = createRouter({
  routes: [
    {
      path: '/',
      component: () => import('./home-page.vue'),
    },
    {
      path: '/profile',
      component: () => import('./profile-page.vue'),
    },
  ],
  history: createWebHistory('/'),
});

Sentry.init({
  app,
  release: import.meta.env.VUE_APP_SENTRY_RELEASE,
  environment: import.meta.env.VUE_APP_SENTRY_ENVIRONMENT,
  dsn: import.meta.env.VUE_APP_SENTRY_DSN,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['localhost', /^\//],
    }),
  ],
  tracingOptions: {
    trackComponents: true,
  },
  attachProps: true,
  attachStacktrace: true,
  logErrors: true,
  tracesSampleRate: 1.0,
});

app.use(router);
app.mount('#app');
